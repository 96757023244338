.search-answers .custom-card {
  padding: 30px 50px;
}

.search-bar {
  margin: 0 auto;
}

.search-bar .form-control {
  background-color: black;
  border: none;
  border-radius: 50px;
  color: white;
  padding-left: 40px;
  font-size: 14px;
  margin: 0 auto;
}

.search-bar .form-control::-webkit-input-placeholder {
  color: #606d87;
  font-weight: 600;
}

.search-bar img:nth-of-type(1) {
  top: 8px;
  left: 8px;
}

.search-bar img:nth-of-type(2) {
  top: 6px;
  right: 8px;
}

.search-answers .back {
  background-color: #586a84;
  width: 100px;
  font-weight: bold;
}

.search-answers .back:hover {
  background-color: #46576f;
  color: initial;
}

.question-body {
  text-align: justify;
  min-height: 19vh !important;
}

.search-answers {
  padding: 15px 10px 10px 10px !important;
  overflow: hidden;
}

/* .Nav-link-container{
  padding-left: 1.8rem !important;
} */
