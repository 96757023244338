:root {
  --primary-color: #04001b;
  --secondary-color: #16151f;
  --primary-text-color: #fff;
  --yellow-color: #edff00;
  --purple-color: #8e30ff;
  --green-color: #219653;
  --orange-color: #ff7723;
  --red-color: #ff001f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "regular";
  src: url("./assets/fonts/Roobert-Regular.woff");
}

@font-face {
  font-family: "semi-bold";
  src: url("./assets/fonts/Roobert-SemiBold.woff");
}
@font-face {
  font-family: "sanchez3";
  src: url("./assets/fonts/SanchezW03-XLt.woff");
}
@font-face {
  font-family: "sanchez5";
  src: url("./assets/fonts/SanchezW05-Regular.woff");
}

html {
  scrollbar-width: thin !important;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
}

body {
  font-family: "regular", sans-serif;
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
  min-height: 100svh;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "semi-bold", sans-serif;
}

.custom-card {
  background-color: var(--primary-color);
  border-radius: 0.45em;
  box-shadow: 0 0 10px #0d0d0d0e;
  padding: 0.5em;
}

.content-container {
  width: 100%;
  padding: 1em;
}
