.list-group-item {
  color: var(--primary-text-color);
  background-color: var(--primary-color);
  font-weight: 600;
  text-wrap: wrap;
  border: 0;
  margin-bottom: 0.75em;
  border-top-left-radius: 0.5em !important;
  border-bottom-left-radius: 0.5em !important;
  padding: 1em;
}

.list-group-item.active {
  background-color: var(--secondary-color);
  color: var(--yellow-color);
  font-weight: bold;
}

.sidebar-nav-link {
  display: flex;
  align-items: center;
}

.avatar-dropdown-container a {
  text-decoration: none;
  color: var(--text-primary-color);
}

.avatar-dropdown-container {
  position: absolute;
  bottom: 8%;
}

.collapse-icon-container{
  position: absolute;
  bottom: 2%;
  padding-left: 10px;
  cursor: pointer;
}

.navbar {
  padding: 1em;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: navbar;
  display: flex;
  align-items: center;
}

.navbar-container {
  width: 100%;
}

.logout-dev span {
  font-size: 12px;
}

.logout-icon{
  padding-right: 15px;
  overflow-x: hidden;
}

.user-details{
  text-align: justify;
}

.tender-sidebar{
  background-color: var(--primary-color);
}

.logout-icon-container{
  padding: 0 0 10px 28px;
}
