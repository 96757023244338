.breadcrumbs {
  display: flex;
  gap: 15px;
}

.breadcrumbs a {
  text-decoration: none;
  color: #8c9bb0;
  font-weight: 600;
  margin-top: 6px;
  font-size: 14px;
}
