#file-uploader {
  border: #4c5b75 2px dashed !important;
}

.file-upload-card,
.uploaded-fileDetails-card {
  background-color: #04001b;
}

.file-upload-text {
  color: #319dff;
}

.file-upload-title {
  color: #4c5b75;
  font-weight: 500;
}

.file-upload-container {
  padding: 1rem 0 5rem 0;
}

.Knowledge-Base #file-search-input {
  background-color: #000000 !important;
  border: none;
}

.Knowledge-Base #file-search-input::placeholder {
  color: #3a434e !important;
  font-size: 0.8rem !important;
  margin-bottom: 5px;
}

.search-icon {
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px 0 0 12px;
  min-height: 70px !important;
}

.knowledge-base-table-content {
  padding-left: 12px !important;
  margin-top: 5px !important;
}

.page-selector {
  padding-right: 35px !important;
  background-color: unset;
  color: #3a434e;
  font-size: 0.8rem;
  font-weight: bolder !important;
}

#file-search-input {
  padding-left: 25px;
  box-sizing: border-box;
}

.Knowledge-Base .submit {
  background-color: white;
  font-weight: bold;
  width: 100px;
}

.Knowledge-Base .submit:hover {
  background-color: #bfbdbd;
  color: initial;
}

.Knowledge-Base .uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 150px;
  border: 2px dashed #586a84;
  border-radius: 5px;
  cursor: pointer;
}

.Knowledge-Base, .file-upload-conatiner{
  padding: 15px 10px 10px 10px !important;
  overflow: hidden;
}
