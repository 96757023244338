#Feedback-text {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  background-color: black;
  margin-top: 15px;
  border: none;
  color: #586a84;
  border-radius: 10px;
  resize: none;
}

.Tender-submit-page{
  padding: 15px 10px 10px 10px !important;
  overflow: hidden;
}

#Feedback-text:focus {
  outline: none;
  border-color: #545e69;
  box-shadow: 0 0 2px rgba(91, 112, 135, 0.5);
}

.circular-mark {
  position: absolute;
  top: 0;
  right: -5px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: red;
}

.progress-bar-container {
  background-color: black;
  border-radius: 10px;
  padding: 50px;
}

.submit-tender .submit {
  background-color: white;
  font-weight: bold;
  color: #555265;
  width: 100px;
}

#minrange {
  margin-top: 13px !important;
}

#tooltip_0_content {
  background-color: rgb(210, 22, 22) !important ;
}

.e-tip-content {
  color: #7a7a80 !important;
}

.text-secondary {
  color: #eef0f3 !important;
}

.p-text {
  font-size: 0.9rem;
  color: #8d8d95 !important;
  font-weight: bold !important;
}

.e-slider-track {
  background-color: #545e69 !important;
  min-height: 3px !important;
}

.e-range {
  background-color: #237b27 !important;
  min-height: 3px !important;
}

.e-handle {
  background-color: #34b53a !important;
}

.progress-bar-container span {
  color: #34b53a;
  margin-left: 10px;
}
