.circular-progress-container {
  background-color: #000000 !important;
  border-radius: 10px;
}

.image-container {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-tender .back {
  background-color: #586a84;
  width: 100px;
  font-weight: bold;
}

.review-tender .back:hover {
  background-color: #46576f;
  color: initial;
}

.text-secondary {
  color: #586a84 !important;
}

.p-text {
  font-size: 0.9rem;
  color: #8d8d95 !important;
  font-weight: bold !important;
}

.review-tender .continue {
  background-color: white;
  font-weight: bold;
  width: 140px;
}

.review-tender .continue:hover {
  background-color: #bfbdbd;
  color: initial;
}

.status-one,
.status-two {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 6px 0 8px !important;
}

.edit-icon {
  background: transparent;
  border: none;
}

.review-tender{
  padding: 15px 10px 10px 10px !important;
  overflow: hidden;
}