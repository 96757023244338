.overview-header {
  display: flex;
  justify-content: space-between;
}

.overview-body {
  width: 80%;
  margin: 0 auto 1.3em auto;
}

.overview-title {
  display: inline-block;
  color: #828b9e;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.custom-input {
  color: #93a2b7 !important;
  font-size: 14px !important;
  background-color: #000000 !important;
  border: none;
}

.custom-input::placeholder {
  color: white;
}

.colored-border {
  border-bottom: 3px solid;
}

.purple-border {
  border-color: var(--purple-color);
}
.green-border {
  border-color: var(--green-color);
}
.orange-border {
  border-color: var(--orange-color);
}
.red-border {
  border-color: var(--red-color);
}

.tender-details-table-content {
  padding-left: 12px !important;
  margin-top: 5px !important;
  min-height: 100vh;
}

.option-icon {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.create-tender-btn {
  min-width: 100px !important;
  min-height: 20px !important;
  border: 0;
  font-weight: 900 !important;
  background-color: white;
  font-size: 0.8rem;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.create-tender-btn:hover {
  background-color: #aaaaab;
}

.status-badge {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 8px 0 8px !important;
}

.over-due-badge {
  background-color: #ff001f;
}

.completed-badge {
  background-color: #219653;
}

.in-progress-badge {
  background-color: #ff7723;
}

.active-badge {
  background-color: #319dff;
}

.submit-badge {
  background-color: #20e8cb;
}

.remaining-status {
  color: #744c0a !important;
  border: 2px solid #744c0a;
  margin: 9px 0 9px 0;
  border-radius: 50px !important;
  padding: 1.7px 5px 1.7px 0 !important;
  font-weight: 600 !important;
}

.overdue-status {
  color: #9a1c4a !important;
  border: 2px solid #9a1c4a;
  margin: 9px 0 9px 0;
  border-radius: 50px !important;
  padding: 1.7px 5px 1.7px 0 !important;
  font-weight: 600 !important;
}

.submitted-status {
  width: 110px !important;
  padding-right: 5px !important;
  background-color: transparent !important;
  border: 2px solid #0da678;
  color: #0da678 !important;
  border-radius: 15px;
  font-weight: 600 !important;
  display: flex;
  margin-top: 2px;
  align-items: center;
  justify-content: center;
}

.page-selector {
  cursor: pointer;
  background-color: #04001b !important;
  color: #606d87 !important;
  font-weight: bold !important;
  padding: 3px !important;
}

.search-icon img {
  vertical-align: unset;
  margin-top: 3px;
}

.tender-url a {
  text-decoration: none;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.submitted-texts p {
  color: #0da678;
  font-size: 12px;
  margin: 0 !important;
}

/* .content-container{
  padding: 15px 10px 10px 10px !important;
  overflow: hidden;
} */

.tender-company:hover{
  cursor: pointer;
}

.e-headertext {
  font-size: 1rem !important;
  white-space: pre;
  text-align: center !important;
}

.e-templatecell{
  padding-bottom: 5px !important;
}

.tender-details-table{
  background-color: var(--primary-color);
}