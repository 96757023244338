.configuration nav {
  display: flex;
  align-items: center;
  gap: 40px;
  color: #586a84;
  font-weight: bold;
  font-size: 18px;
  margin-top: 50px;
  margin-left: 40px;
}

.configuration .nav-link {
  text-decoration: none;
  padding-bottom: 10px;
}

.configuration nav a.active {
  color: white;
  border-bottom: 2px solid white;
}

.configuration {
  padding: 15px 10px 10px 10px !important;
  overflow: hidden;
}
