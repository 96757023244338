.submitted .modal-body {
  padding-top: 20px !important;
}

.submitted .modal-footer {
  border: none !important;
  outline: none !important;
}

.modal-content {
  border: none !important;
}

.submit-text p {
  font-weight: normal;
  font-size: 16px;
}

.submit-text p:nth-of-type(1) {
  color: rgba(255, 255, 0, 0.577);
}

.submitted .btn-submit {
  background-color: yellow;
  color: black;
  font-weight: 600;
  border: none;
}

.submitted .btn-submit:hover {
  background-color: rgba(255, 255, 0, 0.641);
}

.submitted .btn-submit:active {
  background-color: yellow;
  color: black;
  font-weight: 600;
}
