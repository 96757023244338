.rdt_TableHeadRow {
  color: #a2a2a2 !important;
  font-weight: bolder;
  background-color: #383838 !important;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #88888850 !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #63636350 !important;
}

.cRTDms {
  background-color: transparent !important;
}

.rdt_TableBody {
  background-color: #2c2c2c !important;
  color: #ecebeb !important;
  font-size: 14px;
}

.hlUwZf div:first-child {
  white-space: unset !important;
}

.rdt_Pagination {
  background-color: #383838 !important;
  color: #a2a2a2 !important;
  width: 100% !important;
}

.ZeRpf {
  padding: 0px !important;
  background-color: #3b3b3b !important;
}

.answers-table > div:nth-child(2) {
  padding: 0 !important;
}

.sc-eACxAu button {
  padding: 2px !important;
}

.sc-eACxAu > button > svg {
  fill: #a2a2a2;
}
