.feedback-modal .modal-body {
  padding-top: 20px !important;
}

.feedback-modal .modal-footer {
  border: none !important;
  outline: none !important;
}

.feedback-text p {
  font-weight: normal;
  font-size: 16px;
}

.feedback-text p:nth-of-type(1) {
  color: #34b53ac1;
}

.feedback-modal .btn-review {
  background-color: #34b53a;
  color: black;
  font-weight: 600;
  border: none;
}

.feedback-modal .btn-review:hover {
  background-color: #34b53aa7;
}

.feedback-modal .btn-review:active {
  background-color: #34b53a8d;
  color: black;
  font-weight: 600;
}

.feedback-text span {
  color: #34b53ac1;
}
