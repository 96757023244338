.login-form label {
  color: var(--text-primary-color);
}

#email,
#password {
  background-color: var(--bg-secondary) !important;
}

#email:focus,
#password:focus {
  box-shadow: none;
  background-color: var(--bg-secondary) !important;
  border: 2px solid var(--primary-color);
}

#email:active,
#password:active {
  box-shadow: none;
  background-color: var(--bg-secondary) !important;
  border: 2px solid var(--primary-color);
}

.login .card {
  border-width: 2px;
}

.btn-login-intellimorph {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-login-intellimorph:hover {
  background-color: var(--primary-color);
  color: #fff;
}

@media (max-width: 768px) {
  .login .card {
    max-width: 85%;
  }
}

@media (max-width: 425px) {
  .login .card {
    max-width: 95%;
  }
}
