@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 100px;
  height: 100px;
  border: 10px solid #04001b;
  border-top: 10px solid #edff00;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: auto;
  max-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background: rgba(255, 255, 255, 0.18);
  border-radius: 0.375rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.9px);
  -webkit-backdrop-filter: blur(12.9px);
}
