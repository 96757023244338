.e-grid {
  border: 0;
}

.e-content {
  height: auto !important;
  width: 100% !important;
  padding: 0 !important;
}

.e-grid .e-content {
  background-color: #04001b !important;
  width: 100% !important;
  padding: 0 !important;
}

.e-grid .e-headercelldiv {
  font-size: 0.8rem;
  color: #606d87 !important;
  background-color: #04001b !important;
  font-weight: bold !important;
}

.e-icons {
  background-color: #04001b !important;
  color: #ffffff !important;
}

.e-grid .e-headercell,
.e-grid .e-detailheadercell {
  font-size: 0.8rem !important;
  background-color: #04001b !important;
  border-top: 2px solid #747286 !important;
  border-bottom: 1px solid #747286 !important;
}

.e-grid .e-gridheader {
  border: 0;
}

.e-grid .e-gridpager {
  border: 0;
  padding-top: 4px !important;
}

.e-pagesizes {
  visibility: hidden;
}

.e-headercontent {
  border: 0 !important;
}

.e-grid td.e-cellselectionbackground {
  background: #9ac5ee;
  font-style: italic;
}

.option-btn {
  padding: 0.1em;
  border-radius: 50%;
}

.e-grid.e-wrap .e-rowcell,
.e-grid.e-wrap .e-columnheader .e-stackedheadercelldiv,
.e-grid.e-wrap .e-columnheader .e-headercelldiv,
.e-grid.e-wrap .e-columnheader .e-headercell.e-fltr-icon .e-headercelldiv {
  border: none;
  background-color: #04001b !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: normal;
}

.e-grid.e-wrap .e-columnheader .e-headercelldiv {
  color: white !important;
  opacity: 0.8;
}

td {
  font-size: 0.7rem !important;
  font-weight: bold;
  border-bottom: 1px solid #b1b0b5 !important;
  border-bottom-style: solid !important ;
}

.e-pager,
.e-pager .e-pagercontainer {
  border: none !important;
  background-color: transparent;
  color: #606d87;
}

.e-pager .e-numericitem {
  background-color: #04001b;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #f6f4f5;
  color: #fff;
  opacity: 1;
}

.e-pager .e-currentitem.e-numericitem.e-focused {
  background: #f6f4f5;
}

.e-grid .e-detailindentcell {
  border-top-style: solid;
  border-top-width: 1px;
  border-right: 0;
}

.custom-pointer-cursor {
  cursor: pointer;
}

.e-headertext {
  font-size: 1rem !important;
}

.e-grid .e-focused:not(.e-menu-item):not(.e-editedbatchcell) {
  box-shadow: none;
}

.e-grid td.e-cellselectionbackground {
  background: transparent;
  font-style: normal;
}
