.top-right-modal {
  position: absolute;
  top: 42%;
  right: 40px;
}

.filter-modal {
  background-color: #04001b !important;
  color: #586a84 !important;
}

.filter-modal-header{
  background-color: #04001b !important;
  color: #586a84 !important;
  padding: 10px !important;
}

.filter-modal-title {
  color: #62748e !important;
}

.form-check-label {
  text-transform: capitalize;
}

.filter-modal-input{
  background-color: #000000 !important;
  border: 0 !important;
  color: #B3B3B3 !important;
  font-size: 13px !important;
}
