.tender-question-modal {
  background-color: #04001b !important;
  color: #586a84 !important;
}

#tender-question-input {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  background-color: black;
  border: none;
  color: #667b98;
  border-radius: 10px;
  resize: none;
}

.btn-close {
  background-color: #586a84 !important;
}
.btn-cancel-modal {
  width: 100px !important;
  font-size: 14px;
  font-weight: bold;
}

.btn-confirm-modal {
  width: 100px !important;
  font-size: 14px;
  font-weight: bold;
  background-color: #2558AB;
}
.add-question-modal-title {
  color: #62748e !important;
}

#tender-question-input {
  outline: none;
  border-color: #545e69;
  box-shadow: 0 0 2px rgba(91, 112, 135, 0.5);
}
