.avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-menu {
  width: auto;
}

.user-menu.dropdown-menu[data-bs-popper] {
  top: 134%;
  left: -120%;
  margin-top: var(--bs-dropdown-spacer);
}

.notification-container {
  max-height: 50vh;
  padding: 0 1em;
  overflow-y: scroll;
}

.breadcrumb {
  color: var(--text-primary-color);
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: 1.5px;
  margin-bottom: 0.15em;
}

.breadcrumb:hover {
  border-bottom: 2px solid var(--text-primary-color);
}
