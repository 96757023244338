.general-info, .Tender-Nav-link-container, .requirments{
  padding: 15px 10px 5px 10px !important;
  overflow: hidden;
}

.general-info .card {
  background-color: var(--primary-color);
  border-radius: 10px;
}

.general-info .inner-card {
  background-color: black;
  border-radius: 10px;
}

.general-info h5 {
  color: #586a84;
  font-size: 17px;
}

.date-picker:hover{
  cursor: pointer !important;
}

.general-info .form-control {
  background-color: transparent;
  border-color: #343242;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: #586A84;
  border-radius: 3px !important;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
  cursor: pointer;
}

.general-info .uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 150px;
  border: 2px dashed #586a84;
  border-radius: 5px;
  cursor: pointer;
}

.uploader p {
  color: #319dff;
  line-height: 20px;
}

.uploaded-files {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 25px;
  margin-top: 10px;
  color: var(--primary-text-color);
  opacity: 0.6;
  font-size: 15px;
}

.general-info .form-check-input {
  background-color: black;
  width: 18px;
  height: 18px;
  opacity: 0.7;
  cursor: pointer;
}

.general-info .form-check-input:checked {
  background-color: #319dff;
  border: none;
}

.general-info .form-check-label,
.general-info .tender-access p {
  color: var(--primary-text-color);
  opacity: 0.6;
  font-size: 14px;
}

.general-info .next {
  background-color: white;
  font-weight: bold;
  width: 100px;
}

.general-info .next:hover {
  background-color: #bfbdbd;
  color: initial;
}

.general-info .form-control {
  color: var(--primary-text-color);
  opacity: 0.7;
}

.tender-question-card {
  width: 85% !important;
}

.add-question-btn {
  border: 2px solid #2558ab !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 3px 15px 3px 15px;
  margin-right: 9px;
  background-color: transparent !important;
  color: #2558ab;
}

.genarate-answer-btn {
  border:0;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 3px 15px 3px 15px;
  margin-right: 10px;
  background-color: #FFFFFF !important;
}

.date-picker-container{
  position: relative;
}

.add-question-btn:hover {
  color: #224f98;
}

.back-btn{
  background-color: #586A84;
  border-radius: 5px;
  padding: 6px 12px 6px 12px;
  border: 0;
  width: 100px;
}

.back-btn:hover{
  opacity: 0.8;
}

.date-picker-icon-container {
  position: relative;
}

.date-picker-icon {
  position: absolute;
  top: 73%;
  right: 25px;
  transform: translateY(-50%);
}

.input-container {
  position: relative;
}

.pounds-icon {
  position: absolute;
  top: 70%;
  margin-left: 10px;
  transform: translateY(-50%);
}

.input-field {
  padding-left: 22px;
}

.trash-icon:hover{
 cursor: pointer;
}

