.upgrade-tender {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 10px 10px 10px !important;
}

.upgrade-tender .general {
  border: 1px solid var(--yellow-color);
}

.upgrade-tender .card {
  background-color: var(--primary-color);
  border-radius: 10px;
}

.upgrade-tender h5,
.upgrade-tender p {
  color: #586a84;
  font-weight: bolder;
}

.upgrade-tender .answers p {
  color: var(--primary-text-color);
  opacity: 0.7;
  font-weight: 500;
  text-align: justify;
  padding-right: 30px;
  margin-top: 5px;
}

.upgrade-tender .summary p,
.upgrade-tender .finalized-answers p {
  text-align: justify;
}

.upgrade-tender .questions-answers {
  background-color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upgrade-tender .questions-answers .col-11 p {
  margin: 0;
}

.upgrade-tender .expand-icon,
.upgrade-tender .collapse-icon {
  cursor: pointer;
}

.upgrade-tender .expand-icon {
  margin-top: 5px;
}

.questions-answers .card-body {
  width: 100%;
  padding: 75px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.documents p {
  color: #5f5f5f;
  font-weight: bold;
  font-size: 16px;
}

.pagination {
  --bs-pagination-bg: none;
  --bs-pagination-border-color: none;
  --bs-pagination-color: #586a84;
  --bs-pagination-active-bg: #011f4b;
  --bs-pagination-active-border-color: #586a84;
}

.page-link {
  font-size: 12px;
}

.page-link:hover {
  border-radius: 100%;
}

.active > .page-link {
  border-radius: 100%;
  border: 2px solid #586a84;
}

.page-item:first-child .page-link {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.pagination-row p {
  color: #586a84;
  font-size: 12px;
}

.upgrade-tender .back {
  background-color: #586a84;
  width: 140px;
  font-weight: bold;
}

.upgrade-tender .back:hover {
  background-color: #46576f;
  color: initial;
}

.upgrade-tender .submit {
  background-color: white;
  font-weight: bold;
  width: 140px;
}

.upgrade-tender .edit-question {
  background-color: white;
  font-weight: bold;
  width: 150px;
}

.upgrade-tender .edit-question:hover {
  background-color: #bfbdbd;
  color: initial;
}

.upgrade-tender .submit:hover {
  background-color: #bfbdbd;
  color: initial;
}

.upgrade-tender .review {
  background-color: white;
  font-weight: bold;
  width: 140px;
}

.upgrade-tender .review:hover {
  background-color: #bfbdbd;
  color: initial;
}

.upgrade-tender .e-pager .e-numericitem {
  background-color: black;
}

.upgrade-tender .e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #f6f4f5;
  color: #fff;
  opacity: 1;
}

.upgrade-tender .e-pager .e-currentitem.e-numericitem.e-focused {
  background: #f6f4f5;
}

/* .upgrade-tender img {
  cursor: pointer;
} */

.questions p:focus {
  outline: none;
}

.upgrade-tender .btn-group button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.upgrade-tender .finalized-answers {
  background-color: transparent;
  color: #3b87ff;
  border: 1px solid #3b87ff;
  font-weight: bold;
  width: 170px;
  margin-left: 55px;
}

.upgrade-tender .finalized-answers:hover {
  background-color: #6885b4;
  border-color: #6885b4;
  color: initial;
}

.upgrade-tender .finalized-answers:disabled {
  border-color: #586a84;
}

.upgrade-tender .summary p {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1.5rem;
}

.cMiPmJ {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
