.modal-title {
  color: #667b98;
  font-size: 22px;
  opacity: 0.8;
}

.modal-header,
.modal-footer {
  border: none;
  background-color: #04001b !important;
}

.modal-body {
  padding: 8px 16px !important;
  background-color: #04001b !important;
  color: #667b98;
  font-size: 14px !important;
  font-weight: bold !important;
}

.modal-body h5 {
  font-family: "regular", sans-serif;
}
