.selected-answers .card {
  background-color: var(--primary-color);
  border-radius: 10px;
  height: auto;
}

.selected-answers{
  padding: 15px 10px 10px 10px !important;
  overflow: hidden;
}

.selected-answers h5,
.selected-answers p {
  color: #586a84;
  font-weight: bolder;
}

.selected-answers p {
  color: var(--primary-text-color);
  opacity: 0.8;
  font-weight: 500;
}

.selected-answers .continue {
  background-color: #586a84;
  width: 100px;
  font-weight: bold;
}

.selected-answers .continue:hover {
  background-color: #46576f;
  color: initial;
}

.finalizedAnswer-text-input {
  resize: none;
  background-color: #2c2c2c !important;
  border: none;
  color: #ecebeb !important;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 10px;
  text-align: justify;
}

.finalizedAnswer-text-input:focus-within,
.question-text-input:focus-within {
  outline: 1.3px solid #f9f7f7 !important;
}

.question-text-input {
  resize: none;
  background-color: #2c2c2c !important;
  border: none;
  color: #ecebeb !important;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 10px;
}

.suggested-answer {
  text-align: justify;
}

.editable {
  background-color: #4b4b4b !important;
  border: 0.8px solid #444444;
}

.add-question {
  background-color: #cad904;
  font-weight: 600;
}

.selected-answers .add-question:hover {
  background-color: #929c05;
  font-weight: 600;
}

.selected-answers .options button {
  background: transparent;
  border: none;
}

.selected-answers .rdt_TableHeadRow {
  border-radius: 0;
}

.sc-ivnBVK, .sc-fLQSqU{
  background-color: #04001B !important;
  color: #c6c5ca !important;
}
