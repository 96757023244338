:root {
  --layout-padding: 0.5em;
}

.layout {
  height: calc(100vh - var(--layout-padding));
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7rem 1fr;
  grid-template-areas: "main";
  background-color: var(--secondary-color);
  padding: var(--layout-padding);
  padding-bottom: 0;
  overflow-y: hidden;
}

.layout.collapsed {
  grid-template-columns: 75px 1fr;
}

.sidebar {
  margin-bottom: 0.3em;
}

.sidebar.collapsed {
  display: block;
  border-radius: 0.3em !important;
  transform: translateX(0%);
  overflow-y: hidden;
}

.collapse-sidebar,
.collapsed-sidebar {
  height: 100vh;
  border-radius: 0.5em;
  background-color: var(--primary-color);
}

.spand-icon-container{
  padding-bottom: 8px;
  padding-left: 3px;
}

/* 
.navbar {
  padding: 1em;
  position: sticky;
  top: 0;
  z-index: 1;
  grid-area: navbar;
  display: flex;
  align-items: center;
}

.navbar-container {
  width: 100%;
} */

.main {
  grid-area: main;
  overflow-y: auto;
  background-color: var(--secondary-color);
  padding: 1em;
}

.content-container {
  width: 100%;
  min-height: calc(100vh - 92px);
  background-color: var(--bg-sidebar);
  border-radius: 0.3em;
  padding: 1em;
}

.btn-collapse {
  padding: 1em;
  padding-right: 0em;
  border: 0;
  border-radius: 0.4em;
  background-color: transparent;
  cursor: pointer;
}

.sidebar-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.5em;
  padding-right: 0;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (min-width: 770px) {
  .layout {
    grid-template-columns: 250px 1fr;
    grid-template-rows: 7rem 1fr;
    grid-template-areas:
      "sidebar main"
      "sidebar main";
  }

  .content-container {
    padding: 0.6em;
    padding-top: 0;
    overflow-x: hidden;
  }

  .tenderAI-logo {
    margin-top: 10px;
  }

  .sidebar {
    width: 100%;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    padding-right: 0em;
  }

  .sidebar.collapsed {
    display: block;
    transform: translateX(0%);
  }

  .sidebar.medium {
    display: block;
    transform: translateX(0%);
  }

  .btn-collapse.close {
    display: none;
  }
}

@media screen and (max-width: 769px) {
  .sidebar.collapsed {
    height: 100svh;
  }

  .layout {
    grid-template-columns: 1fr 1fr;
    grid-column: auto;
    grid-template-areas:
      "sidebar main"
      "sidebar main";
  }

  .main {
    height: 100svh;
  }

  .tenderAI-logo {
    width: 50px !important;
    margin-top: 10px;
  }

  .collapse-section {
    width: 50vw !important;
  }

  .sidebar-tenderAI-logo {
    margin-top: 10px;
  }

  .collapsed-sidebar {
    display: block !important;
  }
}

.btn-menu {
  z-index: 2;
  width: 28px;
  height: 28px;
  bottom: 15px;
  border-radius: 50%;
  border: 2px solid #edff00;
}

.btn-menu > img {
  border-radius: inherit;
}

.btn-menu:hover,
.btn-menu > img:hover {
  scale: 1.1;
}

.collapse-section {
  position: absolute;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  width: 250px;
  padding-left: 58px;
  padding-bottom: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  z-index: 2;
  height: 35px;
  bottom: 5px;
}

.collapsi-icon-container {
  padding-left: 30px;
}
