.Nav-link {
  color: #586a84 !important;
  cursor: pointer;
}

.Nav-link.active {
  color: rgb(228, 226, 226) !important;
  border-bottom: 2.5px solid rgb(228, 226, 226) !important;
}

.Nav-link {
  padding-bottom: 4px !important;
}

.question-container {
  background-color: black !important;
}

.tender-basics {
  border-left: 3px solid #586a84 !important;
  position: relative !important;
}

.circle-one {
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-left: -7.3px !important;
  margin-bottom: 5px !important;
}

.circle-one {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #edff00;
  margin-bottom: 15px !important;
}

.title-text {
  margin-bottom: 5px;
  position: absolute;
}

.answer-container {
  padding-left: 25px;
  padding-right: 80px;
}

.e-content,
.e-gridheader,
.e-gridpager,
.e-pagercontainer,
.e-numericitem {
  background-color: black;
  color: #606d87 !important;
}

.collapse-icon,
.expand-icon {
  cursor: pointer;
}
