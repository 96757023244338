/* Persona Styles */

.persona .card {
  background-color: var(--primary-color);
  border-radius: 10px;
}

.persona .inner-card {
  background-color: black;
  border-radius: 10px;
}

.persona h5 {
  color: #586a84;
  font-size: 17px;
}

.persona .form-select {
  appearance: none;
}

.persona .form-control,
.persona .form-select {
  background-color: transparent;
  border-color: #343242;
  color: var(--primary-text-color);
  opacity: 0.7;
}

.persona .form-select {
  cursor: pointer;
}

.persona option {
  background: black;
  border: none;
  opacity: 0.7;
}

.persona .next {
  background-color: white;
  font-weight: bold;
  width: 100px;
}

.persona .next:hover {
  background-color: #bfbdbd;
  color: initial;
}

/* Ethos Styles */

.ethos .card {
  background-color: var(--primary-color);
  border-radius: 10px;
}

.ethos .inner-card {
  background-color: black;
  border-radius: 10px;
}

.ethos h5 {
  color: #586a84;
  font-size: 17px;
}

.ethos .form-control {
  background-color: transparent;
  border-color: #343242;
  color: var(--primary-text-color);
  opacity: 0.7;
}

.ethos .next {
  background-color: white;
  font-weight: bold;
  width: 100px;
}

.ethos .next:hover {
  background-color: #bfbdbd;
  color: initial;
}

.ethos .back {
  background-color: #586a84;
  width: 100px;
  font-weight: bold;
}

.persona .back {
  background-color: #586a84;
  width: 100px;
  font-weight: bold;
}

.ethos .back:hover {
  background-color: #46576f;
  color: initial;
}

.persona .back:hover {
  background-color: #46576f;
  color: initial;
}

.ethos-second h5 {
  color: #586a84;
  font-weight: bolder;
}

.ethos-second p {
  color: var(--primary-text-color);
  opacity: 0.7;
  font-weight: 500;
}

.btn:disabled {
  color: #586a84;
}

.e-richtexteditor.e-rte-tb-expand {
  border: none;
}

.e-richtexteditor .e-rte-content {
  background-color: black;
  border-radius: 10px;
  color: var(--primary-text-color);
  margin-top: 15px !important;
  min-height: 200px !important;
  padding: 10px 15px;
}

.e-richtexteditor .e-rte-toolbar {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.e-toolbar-items {
  border-top-left-radius: 10px !important;
}

.e-rte-toolbar,
.e-toolbar .e-toolbar-items,
.e-toolbar .e-toolbar-item .e-tbar-btn {
  background-color: black;
}

.e-toolbar-wrapper {
  background: none !important;
}

.e-icons {
  background: none !important;
  color: #586a84 !important;
}

.e-richtexteditor .e-rte-toolbar .e-hor-nav.e-expended-nav,
.e-toolbar .e-toolbar-item.e-overlay {
  display: none;
}

.e-toolbar .e-toolbar-items {
  gap: 8px;
}

.e-richtexteditor .rte-placeholder {
  color: var(--primary-text-color);
  padding: 13px 0;
  opacity: 0.7;
}

.e-tooltip-wrap {
  display: none !important;
  /* height: 35px !important;
  width: 80px !important;
  position: absolute !important; 
  display: block !important;
  overflow: auto !important */
}

/* .e-tooltip-wrap .e-tip-content {
  color: var(--primary-text-color) !important;
  opacity: 0.8 !important;
  line-height: 15px !important;
} */
